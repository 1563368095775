<template>
  <FormControlBase
    v-slot="{ invalid }"
    :name="name"
    :class="[$style.root, fallthroughClass]"
  >
    <Textarea
      :modelValue="value"
      :label="label"
      :placeholder="placeholder"
      :characterLimit="characterLimit"
      :invalid="invalid"
      :disabled="disabled"
      v-bind="fallthroughAttrs"
      :small="small"
      @input="handleChange"
      @blur="handleBlur"
    >
      <template #message="{ difference }">
        <!-- eslint-disable-next-line vue/no-textarea-mustache -->
        {{ $t('form.textarea.characterLimit', difference) }}
      </template>

      <template v-if="badge" #badge>
        <!-- eslint-disable-next-line vue/no-textarea-mustache -->
        {{ badge }}
      </template>
    </Textarea>

    <div v-if="transliteration">
      <Button
        view="link"
        :class="$style.transliterate"
        :disabled="disabled"
        @click="handleTransliterate(value)"
      >
        {{ $t('form.textarea.transliteration') }}
      </Button>
    </div>

    <div v-if="description">
      <Text size="s" weight="medium" color="secondary">
        {{ description }}
      </Text>
    </div>
  </FormControlBase>
</template>

<script lang="ts">
import { Button, Text, Textarea } from '@smst/ui'
import { useField } from 'vee-validate'
import type { ExtractPropTypes, PropType } from 'vue'
import { defineComponent } from 'vue'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'
import { transliterate } from '@/utils/transliteration'

const formTextareaProps = {
  ...formControlProps,
  placeholder: String,
  characterLimit: Number,
  transliteration: Boolean,
  description: String,
  badge: String,
  small: Boolean,
  trim: Boolean as PropType<boolean>,
} as const

export type FormTextareaProps = ExtractPropTypes<typeof formTextareaProps>

export default defineComponent({
  components: { FormControlBase, Textarea, Text, Button },
  inheritAttrs: false,
  props: formTextareaProps,
  setup(props, { attrs }) {
    const {
      value,
      handleBlur: handleBlurField,
      handleChange,
    } = useField<string>(props.name, undefined, {
      // т.к. modelValue обязательный параметр и используется для вычисления difference и withLabel
      initialValue: '',
    })

    const { class: fallthroughClass, ...fallthroughAttrs } = attrs

    const handleTransliterate = (v: string) => {
      handleChange(transliterate(v))
    }

    const handleBlur = (e: Event) => {
      handleBlurField(e)
      if (props.trim && typeof value.value === 'string') {
        handleChange(value.value.trim())
      }
    }

    return {
      value,
      fallthroughClass,
      fallthroughAttrs,
      handleBlur,
      handleChange,
      handleTransliterate,
    }
  },
})
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;

  gap: var(--gap-8);
}

.transliterate {
  text-decoration: underline;
}
</style>
