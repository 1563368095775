<template>
  <Table
    :data="data"
    :columns="isSmsTemplates ? smsColumns : messengerColumns"
    :rowKey="rowKey"
  >
    <template #column:operator="{ item }">
      {{ getOperatorName(item.operator) }}
    </template>
    <template #column:trafficType="{ item }">
      {{ $t(`trafficTypes.${item.trafficType}`) }}
    </template>
    <template #column:status="{ item }">
      <Text weight="semibold">
        {{ $t(`templates.statuses.${item.status}`) }}
      </Text>
    </template>
    <template #column:service="{ item }">
      {{ item.service.login }}
    </template>
  </Table>
</template>

<script setup lang="ts">
import type { TableTypes } from '@smst/ui'
import { Table, Text } from '@smst/ui'
import type { ComputedRef, PropType } from 'vue'
import { computed } from 'vue'

import type {
  MessengerTemplate,
  Template,
} from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { getOperatorName } from '@/utils/getOperatorName'

import { isSmsTemplateItems } from './TemplatesTable.utils'

const props = defineProps({
  data: {
    type: Array as PropType<Template[] | MessengerTemplate[]>,
    required: true,
  },
})

const isSmsTemplates = computed(() => isSmsTemplateItems(props.data))

const t = useComponentI18n('templatesTable')

const smsColumns: ComputedRef<Array<TableTypes.Column<Template>>> = computed(
  () => [
    {
      name: t('message'),
      prop: 'template',
      wrap: true,
    },
    {
      name: t('operator'),
      prop: 'operator',
    },
    {
      name: t('type'),
      prop: 'trafficType',
    },
    {
      name: t('originator'),
      prop: 'originator',
    },
    {
      name: t('lastUseDate'),
      prop: 'lastUseDate',
    },
  ]
)

const messengerColumns: ComputedRef<
  Array<TableTypes.Column<MessengerTemplate>>
> = computed(() => [
  {
    name: t('message'),
    prop: 'template',
    wrap: true,
  },
  {
    name: t('account'),
    prop: 'service',
  },
  {
    name: t('status'),
    prop: 'status',
  },
])

const rowKey: keyof Template = 'recordId'
</script>
