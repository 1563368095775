import type { SelectTypes } from '@smst/ui'

import { apiClient } from '@/api'
import type { Operator } from '@/api/__generated__/api.schema'
import { OriginatorStatus } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'
import { getOperatorName } from '@/utils/getOperatorName'

import { OriginatorStatuses } from './OriginatorStatuses'

const { t } = i18n.global

export const originatorsStatuses = Object.values(OriginatorStatus)

const getStatusOptions = (): SelectTypes.Option[] => {
  return OriginatorStatuses()
}

const getOperatorOptions = (operators?: Operator[]) => {
  //console.log('operators', operators)
  if (!operators) return []

  apiClient.originators.filters_GET()

  return operators.map((operator) => ({
    value: operator.operatorId,
    label: getOperatorName(operator),
  }))
}

type GetFilterControlOptions = {
  operators?: Operator[]
  originators?: [] | undefined
}

export const initOriginator = ({ operators }: GetFilterControlOptions) => {
  const getFilterControl = () => [
    {
      control: 'filtersOriginators',
      name: 'originator',
      label: t('originators.filters.originator'),
    },
    {
      control: 'select',
      name: 'operatorId',
      label: t('originators.filters.operatorId'),
      options: getOperatorOptions(operators),
      withOptionAll: true,
    },
    {
      control: 'select',
      name: 'status',
      label: t('originators.filters.status'),
      options: getStatusOptions(),
      withOptionAll: true,
    },
  ]

  return { getFilterControl }
}
