<template>
  <div v-if="!isPhoneSourceWithMessage" :class="[$style.root, $attrs.class]">
    <div :class="[$style.header, isSameMessages && $style.disabled]">
      <Text v-if="hasRealDraftName" size="s" color="secondary">
        {{ tSelect('title') }}
      </Text>

      <Text
        :color="draft ? 'primary' : 'secondary'"
        :title="draftNameToDisplay"
        :class="$style.overflow"
      >
        {{ draftNameToDisplay }}
      </Text>
    </div>

    <Button
      type="button"
      view="secondary"
      :disabled="isSameMessages"
      @click="isOpen = !isOpen"
    >
      {{ tSelect('button') }}
    </Button>
  </div>

  <Drawer
    :modelValue="isOpen"
    :title="tDrawer('title')"
    :closeText="tDrawer('closeText')"
    :scroll="true"
    @update:modelValue="handleToggleDrawer"
  >
    <Input v-model="search" :placeholder="tDrawer('placeholder')" />
    <div v-if="showList" :class="$style.list"  @scroll.passive="handleScroll" >
      <ul v-for="(items, index) in data?.pages ?? []" 
        :key="index"
        :class="$style.listul"
        
        >
        <li  v-for="(item, index) in items?.list ?? []" :key="index" :class="$style.item">
          <Checkbox
            :modelValue="selectedDraft?.draftId === item.draftId"
            :class="$style.checkbox"
            @update:modelValue="(value: boolean) => handleCheckbox(value, item)"
          >
            {{ item.name }}
          </Checkbox>
        </li>
        <Loader :class="$style.loading"  v-if="isFetching" size="m" />
      </ul>

    </div>

    <div v-else :class="$style.container">
      <Loader v-if="isFetching" size="m" />

      <NoResultsFound
        v-if="showEmptyResult"
        :variant="MessageType.noResult"
        :withAdvice="false"
      />

      <ApiErrorMessage :error="error" />
    </div>

    <template #buttons>
      <Button
        type="button"
        :disabled="isSubmitDisabled"
        @click="handleSubmitDraft"
      >
        {{ tDrawer('submit') }}
      </Button>

      <Button type="button" view="secondary" @click="handleClearDraft">
        {{ tDrawer('clear') }}
      </Button>

      <Button type="button" view="link" @click="handleToggleDrawer(false)">
        {{ tDrawer('cancel') }}
      </Button>
    </template>
  </Drawer>
</template>

<script lang="ts" setup>
import { Button, Checkbox, Drawer, Input, Loader, Text } from '@smst/ui'
import type { PropType } from 'vue'
import { computed, ref, toRaw, watch, watchEffect } from 'vue'
// import { useQuery } from 'vue-query'

import { apiClient } from '@/api'
import type { Draft } from '@/api/__generated__/api.schema'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import { MessageType } from '@/components/NoResultsFound/NoResultsFound.types'
import NoResultsFound from '@/components/NoResultsFound/NoResultsFound.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import type { Channel } from '@/pages/Drafts/Drafts.utils'
import { useInfiniteQuery } from "vue-query";
// import { getImageSrc } from '@/pages/Batches/Batches.utils'

const props = defineProps({
  isSameMessages: Boolean,
  draft: Object as PropType<Draft>,
  channel: {
    type: String as PropType<Channel>,
    required: true,
  },
  isPhoneSourceWithMessage: Boolean,
})

const emit = defineEmits(['update:draft'])

const tSelect = useComponentI18n('batchCreate.selectDraft')
const tDrawer = useComponentI18n('batchCreate.selectDrawer')

const search = ref<string>('')
const isOpen = ref<boolean>(false)

const hasRealDraftName = computed(() => Boolean(props.draft?.name))
const draftNameToDisplay = computed(() => props.draft?.name ?? tSelect('empty'))

const selectedDraft = ref<Draft | undefined>(props.draft)

watchEffect(() => {
  selectedDraft.value = props.draft
})
const isQueryEnabled = computed(() => isOpen.value && Boolean(search.value))

const sizeList = computed(() => {
  return isQueryEnabled.value ? 100 : 16
})

// const PAGINATION = computed(() => {
//   return { page: 1, size: sizeList.value }
// })

// const searchQuery = computed(() => ({
//   filter: search.value ?? '',
//   ...PAGINATION.value,
// }))

const fetchProjects = async ({ pageParam = 1 }) => {
  // console.log('pageParam', pageParam);
  if(pageParam) {
    const isData = await apiClient.drafts.list_GET_BY(props.channel, {filter: search.value ?? '', page: pageParam, size: sizeList.value });
    // console.log('isData', isData);
    return isData?.data 
  }
}



function useProjectsInfiniteQuery() {
  return useInfiniteQuery("search-draft", fetchProjects, {
    getNextPageParam: (lastPage, pages) => {
      // console.log('lastPage', lastPage);
      // console.log('pages', pages);
      if(lastPage) {
        const page = lastPage?.pageInfo?.currentPage
        const totalPages = lastPage?.pageInfo?.totalPages
        return page && totalPages && page !== totalPages ? page + 1 : null
      }
    }
  });
}
//https://vue-query.vercel.app/#/guides/infinite-queries
const {
  data,
  error,
  fetchNextPage,
  isFetching,
  isSuccess,
  refetch,
} = useProjectsInfiniteQuery();

watch(
  () => search.value,
  async () => {
    await refetch.value({ refetchPage: (page, index) => index === 0 })
  }

)

const handleScroll = async (el) => {
  const scrollTopMax = el.srcElement.scrollHeight - el.srcElement.clientHeight
  const scrollTop = el.srcElement.scrollTop
  // console.log('scrollTop', scrollTop );
  // console.log('scrollTopMax', scrollTopMax);
  if((scrollTop + 40) > (scrollTopMax - 40)) await fetchNextPage.value()
  return
}


const draftsList = computed<Draft[]>(() => {
    const gg = data?.value?.pages ?? []
    // console.log('gg', gg);
    const kk = []
    if(gg?.length) {
      gg.forEach(element => {
        const ll = toRaw(element?.list)
        if(ll) kk.push(...ll)
      });
    }
    return kk
  }
)

const showList = computed(() => isSuccess.value && draftsList?.value?.length > 0)
const showEmptyResult = computed(
  () =>
    isSuccess.value && Boolean(search.value) && draftsList?.value?.length === 0
)

const isSelectedDraftInDraftsList = computed(() =>
  draftsList.value.some(
    (draft) => draft.draftId === selectedDraft.value?.draftId
  )
)
const isSubmitDisabled = computed(() => !isSelectedDraftInDraftsList.value)

const handleCheckbox = (checked: boolean, draft: Draft) => {
  if (checked) {
    selectedDraft.value = draft
  } else {
    selectedDraft.value = undefined
  }
}

const handleToggleDrawer = (value: boolean) => {
  isOpen.value = value
  search.value = ''
  selectedDraft.value = props.draft
}

const handleClearDraft = () => {
  emit('update:draft', undefined)
  handleToggleDrawer(false)
}

const handleSubmitDraft = () => {
  emit('update:draft', selectedDraft.value)
  handleToggleDrawer(false)
}
</script>

<style module>
.root {
  display: flex;

  gap: var(--gap-8);
}

.header {
  display: flex;
  flex: 1;
  flex-direction: column;

  gap: var(--gap-4);
  justify-content: center;
  min-width: 0;
  padding: calc(var(--gap-8) - 2px) var(--gap-12);

  border: 1px solid var(--color-stroke-form);
  border-radius: var(--border-radius-s);
}

.disabled {
  color: var(--color-text-grey);

  background: var(--color-bg-form-disabled);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.loading {
  position: absolute;
  bottom: 14px;
  left: 0;

  width: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  height: 350px;
  max-height: 350px;
  margin: var(--gap-8) 0 0 0;
  padding: 0;
  overflow: hidden auto;

  list-style: none;
  border: 1px solid var(--color-stroke-form);
  border-radius: var(--border-radius-s);
}

.listul {
  margin: var(--gap-8) 0 0 0;
  padding: 0;

  list-style: none;

  /* max-height: 350px;
  height: 350px; */
}

.item {
  padding: 0;

  &:nth-child(odd) {
    background-color: var(--color-bg-table-line);
  }
}

.checkbox {
  width: 100%;
  padding: var(--gap-16) var(--gap-12);
}

.overflow {
  min-width: 0;

  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
