<template>
  <v-select 
    v-model="isData"
    :options="computedOptions" 
    :loading="isLoading" 
    :searchable="true" 
    :placeholder="placeholderBottom" 
    :filterable="false" 
    :reduce="country => country.value"
    @search="handleSearchChange"
    :class="$style.selected"
  >
    <template #search="{ attributes, events }">
      <div :class="$style.placeholder">{{ placeholder }}</div>
      <input
        maxlength="59"
        class="vs__search"
        v-bind="attributes"
        v-on="events"
      />
    </template>
    <template #spinner="{ loading }">
      <Loader v-if="loading" size="s" />
    </template>
    <template #list-footer>
      <div :class="$style.pagination">
        <div :class="[$style.buttonPrev, !hasPrevPage && $style.disabled]" @click="handlePrevPage">{{ t('prev') }}</div>
        <div :class="[$style.buttonNext, !hasNextPage && $style.disabled]" @click="handleNextPage">{{ t('next') }}</div>
      </div>
    </template>
    <template #open-indicator="{ attributes }">
      <IconDown />
    </template>
    <template
      #selected-option-container="{ option, deselect, multiple, disabled }"
    >
      <div class="vs__selected">{{ option.label }}</div>
    </template>
    <template #no-options="{ search, searching, loading }">
      {{ t('textNotFinded') }}
    </template>
  </v-select>
</template>

<script lang="ts">
// import Multiselect from '@vueform/multiselect'
import { isDefined } from 'remeda'
import '@vueform/multiselect/themes/default.css'
import type { PropType } from 'vue'
import { computed, defineComponent, ref, watch } from 'vue'
// import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import type { PaginationTypes } from '@smst/ui'
import { useField } from 'vee-validate'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { IconDown, Loader } from '@smst/ui'

// import { BaseSelect } from '@smst/ui'

import type {
  GetOptions,
  Group,
  Locale,
  OpenDirection,
  Option,
} from '@smst/ui'

const ASYNC_OPTIONS_DELAY = 1000

export default defineComponent({

  inheritAttrs: false,
  props: {
    modelValue: {
      type: Object as PropType<PaginationTypes.Model>,
      required: false,
    },
    placeholderBottom: String,
    options: {
      type: [Array, Function] as PropType<Option[] | Group[] | GetOptions>,
      required: true,
    },
    /*
      для размера 's' не отображается label
    */
    size: {
      type: String as PropType<'s' | 'm'>,
      default: 'm',
    },
    filter: Function,
    hasPrevPage: Boolean,
    hasNextPage: Boolean,
    leftSideSelect: Boolean,
    label: String,
    canDeselect: { type: Boolean, default: true },
    searchable: Boolean,
    placeholder: String,
    openDirection: String as PropType<OpenDirection>,
    locale: Object as PropType<Locale>,
    invalid: Boolean,
    disabled: Boolean,
    groups: Boolean,
    descriptions: String,
    isLoading: Boolean,
    name: String
  },
  emits: ['update:modelValue', 'handlePrevPage', 'handleNextPage', 'search'],
  components: {  Loader, IconDown },
  setup(props, { attrs, emit }) {
    const t = useComponentI18n('originators.filters')
    const asyncMode = computed(() => typeof props.options === 'function')
    const showWarning = ref(false)

    const { handleChange: handleOriginator, value: isData } = useField<string>(
      props?.name ?? 'test'
    )
    const modelSelected = ref()
    const searchValue = ref<string | undefined>(undefined)
    // const isLoading = ref(false)

    const handleSearchChange = (value: string) => {
      searchValue.value = value
      emit('search', value)
    }
    const handlePrevPage = () => {
      emit('handlePrevPage')
    } 

    const handleNextPage = () => {
      emit('handleNextPage')
    }

    const withLabel = computed(() =>
      Boolean(isDefined(props.modelValue) ?? searchValue.value)
    )

    const leftSideInner = computed(() => {
      if (props.leftSideSelect) return 'dropdown'
      return ''
    })
    const leftSideItem = computed(() => {
      if (props.leftSideSelect) return 'options'
      return ''
    })

    watch(
      () => modelSelected.value,
      (newVal) => {
        handleOriginator(newVal)
        emit('update:modelValue', newVal)
      }
  
  )

    const setSelected = (value) => {
      // console.log('value', value);
      emit('update:modelValue', value)
    }

    const computedOptions = computed(() => {
      if (asyncMode.value) {
        const interceptor = async (query: string) => {
          const { options, hasMore } = await (props.options as GetOptions)(
            query
          )

          showWarning.value = hasMore

          return options
        }

        return interceptor
      }

      return props.options
    })

    const asyncProps = asyncMode.value
      ? {
          filterResults: false,
          minChars: 1,
          resolveOnLoad: false,
          delay: ASYNC_OPTIONS_DELAY,
        }
      : {}

    const { class: fallthroughClass, ...fallthroughAttrs } = attrs

    return {
      computedOptions,
      asyncProps,
      showWarning,
      withLabel,
      fallthroughClass,
      fallthroughAttrs,
      handleSearchChange,
      leftSideInner,
      leftSideItem,
      handlePrevPage,
      handleNextPage,
      modelSelected,
      setSelected,
      isData,
      t,
    }
  },
})
</script>

<style>
/* stylelint-disable selector-class-pattern */
.vs--searchable .vs__dropdown-toggle {
  padding: 18px 6px 11px;
}

.vs__dropdown-option {
  position: relative;

  display: flex;
  padding: 5px 12px;
}

.vs__dropdown-option::after {
  position: absolute;
  right: var(--option-padding);
  bottom: 0;
  left: var(--option-padding);

  width: 90%;

  border-top: 1px solid var(--color-stroke-blocks);

  content: "";
}

.vs__dropdown-option--selected, .vs__dropdown-option--highlight {
  color: var(--color-bg-button);
  font-weight: 700;

  background: inherit;
}
</style>

<style module>
.main {
  --ms-max-height: none;
  --option-padding: var(--gap-12);
  --option-gap: var(--gap-8);

  scrollbar-width: 6px;
  scrollbar-color: var(--main-bg-color) transparent;

  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--main-bg-color);

    border-radius: var(--border-radius-s);
  }
}

.placeholder {
  position: absolute;
  top: -10px;
  left: 9px;
  z-index: var(--z-label);

  color: var(--color-text-grey);
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  letter-spacing: .02em;
}

.buttonPrev, .buttonNext {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: 2px;
  margin-bottom: 3px;
  margin-left: 2px;
  padding: 5px;

  color: var(--color-text-white);

  background: var(--color-bg-button);
  border-radius: 4px;
  cursor: pointer;
}

.disabled {
  opacity: 0.3;
}

.buttonPrev:hover, .buttonNext:hover {
  background: var(--color-bg-button-hover);
}


.root-s {
  padding: var(--gap-4) var(--gap-8);
}

.placeholder-s {
  top: var(--gap-4);
  left: var(--gap-8);
}

.singleLabel-s {
  bottom: var(--gap-4);
}

.caret-s {
  top: var(--gap-4);
  right: var(--gap-8);
}

.markSelected {
  color: var(--color-bg-button);
  font-weight: 700;
}

.groupLabel {
  position: relative;

  padding: var(--gap-12);

  font-weight: 600;

  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
}

.groupLabel::after {
  position: absolute;
  right: var(--gap-12);
  bottom: 0;
  left: var(--gap-12);

  border-top: 1px solid var(--color-stroke-blocks);

  content: '';
}

.leftSideInner {
  position: fixed;
  top: 0;
  right: 490px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 100%;
}

.leftSideItem {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 150px;
  max-height: 255px;

  padding: var(--option-padding);
  overflow-y: scroll;

  color: var(--color-text-dark);
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);

  white-space: nowrap;
  text-align: left;

  background: var(--color-bg-white);
  border-radius: var(--border-radius-m);
  outline: none;
  box-shadow: var(--shadow-lvl-1);

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-stroke-blocks);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-text-dark);
    border-radius: var(--border-radius-s);
  }
}

.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

</style>
