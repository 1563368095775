<template>
  <div :class="$style.root">
    <Text tag="h1">
      {{ t('loginPrivateAreaTitle', { login: login }) }}
    </Text>

    <form :class="$style.form" @submit="onSubmit">
      <Button type="submit" :class="$style.submit" :loading="isLoading">
        {{ t('form.submit') }}
      </Button>
      <ApiErrorMessage :error="error" :class="$style.submitError" />
    </form>
  </div>
</template>

<script setup lang="ts">
import { Button, Text } from '@smst/ui'
import { useForm } from 'vee-validate'
import { computed } from 'vue'
import { useMutation } from 'vue-query'
import { useRoute } from 'vue-router'

import { apiClient } from '@/api'
import type { LoginResult } from '@/api/__generated__/api.schema'
import { setAuthTokens } from '@/api/authTokens'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { RouteNames } from '@/routeNames'
import { router } from '@/router'
import { checkFrontByDomain } from '@/utils/getDomain'

const t = useComponentI18n('loginPrivateArea')
const routeApp = useRoute()
const secreteCode = () => {
  if(checkFrontByDomain()) return import.meta.env.VITE_SECRETE_ADMIN_CODE_BY as string
  else return import.meta.env.VITE_SECRETE_ADMIN_CODE as string
}

const login = routeApp.query.login
const secrete = routeApp.query.secrete ?? ''
const tokenId = routeApp.query.tokenId

const initialValues = computed(() => ({
  login: login ?? '',
  tokenId: tokenId ?? '',
}))

const { handleSubmit } = useForm({
  initialValues,
})

const auth = (data: LoginResult) => {
  setAuthTokens({
    accessToken: data.accessToken,
    refreshToken: data.refreshToken,
  })
  void router.push({ name: RouteNames.Dashboard })
}

const checkSecrete = () => {
  if (!initialValues.value.login || !secrete || !initialValues.value.tokenId) {
    void router.push({ name: RouteNames.Auth })
  } else if (secrete && secrete !== secreteCode()) {
    void router.push({ name: RouteNames.Auth })
  }
}

const {
  mutate: loginPrivateArea,
  isLoading,
  error,
} = useMutation(apiClient.auth.loginPrivateArea_POST, {
  onSuccess: ({ data }) => {
    auth(data)
  },
})

const onSubmit = handleSubmit((values) => loginPrivateArea(values))

checkSecrete()
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: var(--gap-32);
}

.submit {
  width: 100%;
  margin-top: -5%;
}

.submitError {
  margin-top: var(--gap-8);
}
</style>
