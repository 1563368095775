<template>
  <HintCard v-if="isVisible" :class="$style.shortenUrlHint">
    <Text weight="bold" color="success" tag="p">
      {{ t('title') }}
    </Text>

    <Text weight="bold" tag="p">
      {{ t('subtitle') }}
    </Text>

    <Text tag="p"> {{ newTexted }} </Text>
  </HintCard>
</template>

<script lang="ts" setup>
import { Text } from '@smst/ui'
import { computed } from 'vue'

import HintCard from '@/components/HintCard/HintCard.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'

const t = useComponentI18n('batchCreate.shortenUrl')

const props = defineProps({
  value: String,
})

const linkExample = 'go5.io/zy6p59q'
// eslint-disable-next-line prefer-named-capture-group, prefer-regex-literals
const linkRegex =
  // eslint-disable-next-line prefer-named-capture-group
  /^(http|https|\nhttp|\n\nhttp|\n\n\nhttp|\n\n\n\nhttp|\nhttps|\n\nhttps|\n\n\nhttps|\n\n\n\nhttps):\/\/\/?.(www\.)?([a-zА-Яа-я\d][a-zА-Яа-я\d-]*\.?)*\.[a-zА-Яа-я\d-]{2,8}(\/([\w#!:.?+=&%@\-/])*)?/gi

const textArr = computed(() => props?.value?.split(' '))

const isUrl = computed(() => {
  const urls: string[] = []
  if (textArr.value && textArr.value.length > 0)
    textArr.value.forEach((element) => {
      const findUrl = element.match(linkRegex)
      if (findUrl) urls.push(...findUrl)
    })
  return urls
})
const isVisible = computed(() => {
  return isUrl.value.length > 0
})

const newTexted = computed(() => {
  // const urls: string[] = []
  let newText = props?.value

  if (isUrl.value.length > 0)
    isUrl.value.forEach((item, index) => {
      const newTxt = newText?.replace(item, linkExample + index)
      newText = newTxt
    })
  return newText
})
</script>

<style module>
.shortenUrlHint {
  display: flex;
  flex-direction: column;
  row-gap: var(--gap-8);
}
</style>
