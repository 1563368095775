<template>
  <Confirmation
    v-if="confirmation"
    :phone="confirmation.phone"
    :error="errorConfirm"
    :reset="resetConfirmation"
    @confirm="handleConfirm"
  >
    <Generate :sessionId="confirmation.sessionId" />
  </Confirmation>

  <template v-else>
    <TextUi tag="h1" :class="$style.title">{{ $t('login.title') }}</TextUi>

    <TextUi tag="p" weight="medium" :class="$style.note">
      {{ $t('login.note') }}
    </TextUi>

    <form :class="$style.form" @submit="onSubmit">
      <FormControls :controls="formControls" />

      <LinkComponent
        :to="{ name: RouteNames.ForgotPassword }"
        :class="$style.forgot"
        view="link"
      >
        {{ $t('login.forgotPassword') }}
      </LinkComponent>
      <ButtonUi
        type="submit"
        :loading="isLoading"
        :class="$style.submit"
        :disabled="buttonDisabled"
        fullWidth
      >
        {{ $t('login.form.submit') }}
      </ButtonUi>

      <ApiErrorMessage :error="error" :class="$style.submitError" />
    </form>
    <FormRecaptcha
      v-if="errorActive"
      name="captchaCode"
      secondTime
      :class="$style.captcha"
      @reCaptcha="handleRecaptcha"
    />
    <TextUi v-if="checkFrontRuDomain()">
      <span>{{ $t('login.noAccount') }}</span>
      {{ ' ' }}
      <LinkComponent inline :to="{ name: RouteNames.Registration }">
        {{ $t('login.register') }}
      </LinkComponent>
    </TextUi>
  </template>
</template>

<script lang="ts">
import { Button as ButtonUi, Text as TextUi } from '@smst/ui'
import { useForm } from 'vee-validate'
import { computed, defineComponent } from 'vue'

import type { Login } from '@/api/__generated__/api.schema'
import { authTokens } from '@/api/authTokens'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Confirmation from '@/components/Confirmation/Confirmation.vue'
import FormControls from '@/components/FormControls/FormControls.vue'
import FormRecaptcha from '@/components/FormRecaptcha/FormRecaptcha.vue'
import LinkComponent from '@/components/Link/Link.vue'
import { RouteNames } from '@/routeNames'
import { router } from '@/router'
import { checkFrontRuDomain } from '@/utils/getDomain'

import { formControls } from './Auth.control'
import { authFormSchema } from './Auth.schema'
import { useLogin } from './Auth.utils'
import {
  captchaToken,
  errorActive,
  errorCode,
  getErrorCode,
  setCaptcha,
} from './AuthCaptcha.utils'
import Generate from './components/Generate/Generate.vue'

export default defineComponent({
  components: {
    FormControls,
    ApiErrorMessage,
    ButtonUi,
    TextUi,
    LinkComponent,
    Confirmation,
    Generate,
    FormRecaptcha,
  },
  setup() {
    const { handleSubmit, meta, setFieldValue } = useForm<Login>({
      validationSchema: authFormSchema,
    })

    const {
      login,
      isLoading,
      error,
      confirmation,
      confirm,
      errorConfirm,
      isLoadingConfirm,
      resetConfirmation,
    } = useLogin()

    const buttonDisabled = computed(() => {
      if (errorActive.value && !captchaToken.value) return true
      else return !meta?.value.valid && meta?.value.touched
    })

    const handleRecaptcha = (res: string) => {
      setFieldValue('captchaCode', res)
      setCaptcha(res)
    }

    const handleConfirm = (code: string) => {
      if (!confirmation?.value) return

      confirm({
        secreteCode: code,
        sessionId: confirmation.value?.sessionId,
      })
    }

    return {
      onSubmit: handleSubmit((values) => login(values)),
      isLoading,
      error,
      RouteNames,
      formControls,
      confirmation,
      handleConfirm,
      errorConfirm,
      isLoadingConfirm,
      meta,
      handleRecaptcha,
      errorActive,
      errorCode,
      buttonDisabled,
      resetConfirmation,
      checkFrontRuDomain,
    }
  },
  mounted() {
    getErrorCode()
    if (authTokens.value) {
      void router.push({ name: RouteNames.Dashboard })
    }
  },
})
</script>

<style module>
.title {
  margin-bottom: var(--gap-16);
}

.note {
  white-space: pre-line;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: var(--gap-32) auto var(--gap-24);

  text-align: left;
}

.forgot {
  display: block;
  align-self: center;
  margin-top: var(--gap-8);

  font-size: var(--font-size-s);
}

.submit {
  margin-top: var(--gap-32);
}

.submitError {
  margin-top: var(--gap-8);

  text-align: center;
}
</style>
