const mainHost = location.hostname
const domainSTMEDIA = 'lk.smarttek.media'
const frontRU = 'front.smstraffic.ru'
const domainRitorica = 'front.ritorica.com'
const frontUz = 'front.smstraffic.uz'
const frontKz = 'front.smstraffic.kz'
const frontBy = 'front.smstraffic.by'
const frontIo = 'front.smstraffic.io'
const frontLkisgBy = 'lkisg.smstraffic.by'
const domainStageFront = 'front-stage.smstraffic.ru'

export const checkMainDomain = (domain: string): boolean => {
  return domain === mainHost
}

export const checkFrontRuDomain = (): boolean => {
  return mainHost === frontRU
}
export const checkFrontIoDomain = (): boolean => {
  return mainHost === frontIo
}

export const getMainDomain = (): string => {
  return mainHost
}
export const checkStMediaDomain = (): boolean => {
  return mainHost === domainSTMEDIA
}

export const checkRitoricaDomain = (): boolean => {
  return mainHost === domainRitorica
}

export const checkStageFront = (): boolean => {
  return mainHost === domainStageFront
}

export const checkFrontUzDomain = (): boolean => {
  return mainHost === frontUz
}

export const checkFrontByDomain = (): boolean => {
  return mainHost === frontBy || mainHost === frontLkisgBy
}
export const checkFrontKzDomain = (): boolean => {
  return mainHost === frontKz
}
export function getDomainForRegistration() {
  if (checkFrontUzDomain()) return 'www.smstraffic.uz'
  if (checkRitoricaDomain()) return 'www.ritorica.com'
  if (checkFrontKzDomain()) return 'www.smstraffic.kz'
  if (checkFrontByDomain()) return 'www.smstraffic.by'
  return 'www.smstraffic.ru'
}
