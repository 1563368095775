<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <Row>
    <Card small :title="t('message.title')" :withRoundedCorners="false">
      <Text tag="p" weight="medium" :class="$style.marginBottom8">
        {{ t('message.description') }}
      </Text>
      <FormSelect
        v-if="formSelectActive"
        name="routeGroupId"
        :label="t('message.route.label')"
        :options="messageRouteOptions"
        :searchable="true"
        :canDeselect="false"
        :class="$style.marginBottom8"
        :defaultId="defaultId"
        :disabled="isPhoneSourceWithMessage"
      />

      <Text
        tag="p"
        size="s"
        weight="medium"
        color="secondary"
        :class="$style.marginBottom24"
      >
        {{ t('message.route.description') }}
      </Text>

      <SelectDraft
        v-if="!isPhoneSourceWithMessage"
        v-model:draft="commonDraft"
        channel="all"
        :class="$style.marginBottom8"
      />

      <FormCheckbox
        v-show="!isPhoneSourceWithMessage"
        :value="true"
        name="isSameMessages"
        :label="t('message.isSameMessages')"
        :class="$style.marginBottom24"
      />

      <FormInput
        name="originator"
        :label="t('message.originator.label')"
        :class="$style.marginBottom8"
        :disabled="originatorDisable"
      />

      <Text
        v-if="!originatorDisable"
        tag="p"
        size="s"
        weight="medium"
        color="secondary"
      >
        {{ t('message.originator.description') }}
      </Text>
    </Card>

    <Card
      v-for="(route, index) in routeGroup"
      :key="route.name"
      :view="route.name"
      :withRoundedCorners="false"
      :class="$style.channelCard"
    >
      <Text tag="p" size="xl" weight="semibold" :class="$style.channelTitle">
        {{ $t(`channels.${route.name}`) }}
      </Text>

      <Draft
        :isSameMessages="checkboxToBoolean(isSameMessages)"
        :isDependentRoute="checkboxToBoolean(isSameMessages) && index > 0"
        :commonDraft="commonDraft"
        :channel="getChannelForDraft(route?.name)"
        :name="`channels.${route?.name}.message`"
        :label="t('message.channels.common.textarea.label')"
        :placeholder="t('message.channels.common.textarea.placeholder')"
        :transliteration="route.name === Channel.Sms"
        :characterLimit="getCharacterLimit(route?.name)"
        :badge="getBadge(route?.name)"
        :class="$style.marginBottom8"
        :hint="getHint(route?.name)"
        :hintValue="smsMessageValue"
        :isPhoneSourceWithMessage="isPhoneSourceWithMessage"
        @commonDraftReset="handleCommonDraftReset"
      />

      <FormCheckbox
        v-if="route?.name === Channel.Sms"
        name="rus"
        :label="t('message.channels.sms.rus')"
        :value="true"
      />

      <template
        v-if="
          (route?.name === Channel.Viber || route?.name === Channel.Whatsapp) &&
          !isPhoneSourceWithMessage
        "
      >
        <Text
          tag="p"
          weight="semibold"
          :class="[$style.marginTop24, $style.marginBottom8]"
        >
          {{ t(`message.channels.${route?.name}.image`) }}
        </Text>

        <FormUploadImage
          :name="`channels.${route?.name}.fileUrl`"
          :locale="fileLocale"
          useUrl
          :maxSize="FILE_SIZE_KB"
          :descriptions="[
            tFile('acceptFiles', { types: FILE_EXT }),
            tFile('additional', { size: FILE_SIZE_KB / 1024 }),
          ]"
        />

        <template
          v-if="route?.name === Channel.Viber && !isPhoneSourceWithMessage"
        >
          <Text
            tag="p"
            weight="semibold"
            :class="[$style.marginTop24, $style.marginBottom8]"
          >
            {{ t(`message.channels.viber.button`) }}
          </Text>

          <Text tag="p" weight="medium" :class="$style.marginBottom8">
            {{ t(`message.channels.viber.description`) }}
          </Text>

          <FormInput
            name="channels.viber.buttonName"
            :label="t('message.channels.viber.buttonName.label')"
            :placeholder="t('message.channels.viber.buttonName.placeholder')"
            :class="$style.marginBottom8"
          />

          <FormInput
            name="channels.viber.buttonUrl"
            :label="t('message.channels.viber.buttonUrl.label')"
            :placeholder="t('message.channels.viber.buttonUrl.placeholder')"
          />
        </template>
      </template>
      <TimeOut
        v-if="showTimeOut(route?.name)"
        :name="route?.name"
        :timeOut="route?.timeout"
      />
    </Card>

    <template #right>
      <HintCard v-html="tHint('message')" />
    </template>
  </Row>
</template>

<script lang="ts" setup>
import { Text } from '@smst/ui'
import { useFieldValue } from 'vee-validate'
import type { PropType } from 'vue'
import { computed, nextTick, ref, toRaw, watchEffect } from 'vue'
import { useQuery } from 'vue-query'
import { useRoute } from 'vue-router'

import { apiClient } from '@/api'
import type { Draft as DraftType } from '@/api/__generated__/api.schema'
import Card from '@/components/Card/Card.vue'
import FormCheckbox from '@/components/FormCheckbox/FormCheckbox.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import FormSelect from '@/components/FormSelect/FormSelect.vue'
import { Row } from '@/components/Grid'
import HintCard from '@/components/HintCard/HintCard.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { checkboxToBoolean } from '@/utils/checkbox'
import { getSmsFormData } from '@/utils/sms'

import type { Route, SendRoutes } from '../BatchForm/BatchForm.utils'
import {
  Channel,
  channelsLimits,
  FILE_EXT,
  FILE_SIZE_KB,
} from '../BatchForm/BatchForm.utils'
import Draft from '../Draft/Draft.vue'
import FormUploadImage from '../FormUploadImage/FormUploadImage.vue'
import SelectDraft from '../SelectDraft/SelectDraft.vue'
import ShortenUrlHint from '../ShortenUrlHint/ShortenUrlHint.vue'
import TimeOut from '../TImeout/TimeOut.vue'

const t = useComponentI18n('batchCreate.form')
const tFile = useComponentI18n('batchCreate.form.message.file')
const tHint = useComponentI18n('batchCreate.hint')
const tChannels = useComponentI18n('channels')
const { profile } = useProfile()

const props = defineProps({
  routes: {
    type: Object as PropType<SendRoutes>,
    required: true,
  },
  isPhoneSourceWithMessage: Boolean,
  isSmsRoute: Boolean,
})

const showTimeOut = (nameRoute: string | undefined) => {
  if (nameRoute === 'sms') {
    if (props.isSmsRoute) {
      return true
    } else {
      return false
    }
  } else {
    if (profile?.value?.customRouteTimeouts) {
      return true
    } else {
      return false
    }
  }
}

const originatorDisable = computed(() => {
  return profile?.value?.selfRegistered
})

const customRouteTimeouts = computed(() => {
  return profile?.value?.customRouteTimeouts
  // return false
})

const fileLocale = {
  title: tFile('title'),
  deleteFile: tFile('delete'),
  errors: {
    WrongFormat: tFile('errors.WrongFormat'),
    FileTooLarge: tFile('errors.FileTooLarge'),
  },
}

const commonDraft = ref<DraftType | undefined>(undefined)
const handleCommonDraftReset = () => {
  commonDraft.value = undefined
}

const routeApp = useRoute()
const queryDraftId = routeApp.query.draftId as unknown as number | undefined
const isSameMessages = useFieldValue<boolean[]>('isSameMessages')

const defaultId = computed(() => {
  const routes = toRaw(props.routes).filter((item) => item.isDefault === true)
  const idEnt = routes?.gid
  let idRoute = idEnt

  toRaw(props.routes).forEach((item) => {
    if (item?.channels?.at(0)?.name === 'sms') idRoute = item.gid
  })

  return idRoute
})

useQuery(
  ['initial-draft'],
  () => {
    if (queryDraftId) {
      return apiClient.draft.draft_GET_BY(queryDraftId)
    }
  },
  {
    enabled: Boolean(queryDraftId),
    onSuccess: (response) => {
      if (response) {
        commonDraft.value = response.data.draft
      }
    },
  }
)

const messageRouteOptions = computed(() => {
  if (props.isPhoneSourceWithMessage) {
    return toRaw(props.routes)
      .filter(({ channels }) => {
        // const routeGroup = channels

        if (channels?.length && channels?.length > 1) {
          return false
        }

        return true
      })
      .map(({ gid, channels }) => ({
        value: gid,
        label: channels?.map((route) => tChannels(route?.name)).join(' > '),
      }))
  }

  return toRaw(props.routes).map(({ gid, channels }) => ({
    value: gid,
    label: channels?.map((route) => tChannels(route?.name)).join(' > '),
  }))
})

const routeGroupIdValue = useFieldValue<string>('routeGroupId')
const routeGroup = computed(() => {
  const group = toRaw(props.routes).filter(
    (item) => item.gid === routeGroupIdValue.value
  )
  // console.log('group', group)
  // return group.at(0).channels.map((item) => item.name)
  return group.at(0)?.channels
})

const getChannelForDraft = (route: Route) => {
  if (route === 'ok') {
    return 'vk'
  }

  if (route === 'push' || route === 'email') {
    return 'all'
  }

  return route
}

const rusValue = useFieldValue<boolean[] | undefined>('rus')
const isCyrillic = computed(() => {
  /*
    Из-за особенности работы vee-validate с частями формы, которые
    появляются и скрываются, значение может быть undefined
  */
  if (rusValue.value === undefined) {
    return false
  }

  return checkboxToBoolean(rusValue.value)
})

const smsMessageValue = useFieldValue<string | undefined>(
  'channels.sms.message'
)

const smsFormData = computed(() =>
  getSmsFormData(smsMessageValue.value ?? '', isCyrillic.value)
)

const getCharacterLimit = (route: Route) => {
  if (route === Channel.Sms) {
    return smsFormData.value.characterLimit
  }

  return channelsLimits[route]
}

const getBadge = (route: Route) => {
  if (route === Channel.Sms) {
    return smsFormData.value.badge
  }

  return undefined
}

const isShortenUrl = computed(() => profile.value?.shortenUrl)

const formSelectActive = ref(true)

const getHint = (route: Route) => {
  if (route === Channel.Sms && isShortenUrl.value) {
    return ShortenUrlHint
  }
  return undefined
}
const reloadSmsSelect = async () => {
  formSelectActive.value = false
  await nextTick()
  formSelectActive.value = true
}
watchEffect(() => {
  if (props.isPhoneSourceWithMessage) {
    commonDraft.value = undefined
    void reloadSmsSelect()
  }
})
</script>

<style module>
.marginBottom8 {
  margin-bottom: var(--gap-8);
}

.marginTop24 {
  margin-top: var(--gap-24);
}

.marginBottom24 {
  margin-bottom: var(--gap-24);
}

.channelCard {
  margin-top: var(--gap-4);
}

.channelTitle {
  margin-bottom: var(--gap-12);
}
</style>
