<template>
  <form autocomplete="off" @submit="onSubmit">
    <Grid>
      <PhonesSource
        :groups="groups"
        :testPhones="testPhones"
        :selectedGroups="selectedGroups"
        :selectedPhonesData="selectedPhonesData"
      />
      <Message
        :isSmsRoute="isSmsRoute"
        :routes="routes"
        :isPhoneSourceWithMessage="isPhoneSourceWithMessage"
      />
      <Period />
      <Row>
        <Card slide small :title="t('name.title')" :description="t('name.description')">
          <FormInput name="batchName" :placeholder="t('name.placeholder')" />
        </Card>
      </Row>
      <PreliminaryEvaluation
        v-if="isSmsRoute"
        :disabled="isSubmitDisabled || !meta.valid"
        :values="prepareDataToRequest(valuesForm)"
      />
    </Grid>

    <div v-if="error" :class="$style.errorContainer">
      <ApiErrorMessage :error="error" />
    </div>

    <div :class="$style.buttons">
      <Button type="submit" :loading="isLoading" :disabled="isSubmitDisabled">
        {{ t('continueButton') }}
      </Button>

      <Button
        v-if="showTestButton"
        :class="$style.testButton"
        type="button"
        :disabled="isSubmitDisabled"
        view="secondary"
        @click="onTest"
      >
        {{ t('testButton') }}
      </Button>
    </div>
  </form>
</template>

<script lang="ts" setup>
import type { MultiselectTypes } from '@smst/ui'
import { Button } from '@smst/ui'
import { useFieldValue, useForm, useFormValues } from 'vee-validate'
import { computed, ref, toRaw, watch, watchEffect } from 'vue'
import type { PropType } from 'vue'

import type { Group } from '@/api/__generated__/api.schema'
import { PhonesType, PhonesTypeFile } from '@/api/__generated__/api.schema'
import ApiErrorMessage from '@/components/ApiErrorMessage.vue'
import Card from '@/components/Card/Card.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import { Grid, Row } from '@/components/Grid'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { Role } from '@/hooks/usePermissions'
import { useProfile } from '@/hooks/useProfile'
import { booleanToCheckbox, checkboxToBoolean } from '@/utils/checkbox'
import { formatStringTimeToServerTime } from '@/utils/formatDateToDisplay'
import { globalTime } from '@/utils/localTimeFromServer/globalLocalTime'

import type {
  SelectedElements,
  SelectedPhonesData,
} from '../../BatchCreate.utils'
import Message from '../Message/Message.vue'
import Period from '../Period/Period.vue'
import PhonesSource from '../PhonesSource/PhonesSource.vue'
import PreliminaryEvaluation from '../PreliminaryEvaluation/PreliminaryEvaluation.vue'

import type { ExtraPhonesType, FormValues, RoutesType } from './BatchForm.utils'
import {
  displayData,
  getValidationSchema,
  PhonesSourceTypeEnums,
} from './BatchForm.utils'

const props = defineProps({
  isSmartDelivery: {
    type: Boolean,
    default: false,
  },
  defaultBlackListId: {
    type: Number,
    default: 0,
  },
  defaultOriginator: {
    type: String,
    default: '',
  },
  rusByDefaultDisabled: {
    type: Boolean,
    default: false,
  },
  routes: {
    type: Object as PropType<RoutesType>,
    required: true,
  },
  groups: {
    type: Array as PropType<Group[]>,
    required: true,
  },
  testPhones: Array as PropType<MultiselectTypes.Option[]>,
  selectedGroups: Object as PropType<SelectedElements>,
  selectedPhonesData: Object as PropType<SelectedPhonesData>,
  isLoading: Boolean,
  error: Object as PropType<unknown>,
  submitted: Boolean,
})

const t = useComponentI18n('batchCreate.form')
const { profile, profileType } = useProfile()

const saveRoutedId = ref('')

const getPhonesSource = () => {
  if (profileType === 'selfRegistered') {
    return {
      phonesSourceType: PhonesSourceTypeEnums.CommaSeparated,
      phonesSourceTextarea: [],
    }
  }

  if (props.selectedGroups) {
    return {
      phonesSourceType: PhonesSourceTypeEnums.SelectedGroups,
      phonesSourceTextarea: '',
    }
  }

  if (props.selectedPhonesData?.selectedPhones) {
    const { selectedPhones } = props.selectedPhonesData

    if (selectedPhones.all && selectedPhones.exclude.length > 0) {
      return {
        phonesSourceType: PhonesSourceTypeEnums.SelectedPhones,
        phonesSourceTextarea: '',
      }
    }

    if (!selectedPhones.all && selectedPhones.include.length > 0) {
      const phonesText = selectedPhones.include.join(', ')

      return {
        phonesSourceType: PhonesSourceTypeEnums.CommaSeparated,
        phonesSourceTextarea: phonesText,
      }
    }
  }

  return {
    phonesSourceType: PhonesSourceTypeEnums.CommaSeparated,
    phonesSourceTextarea: '',
  }
}

const getInitialValues = (): FormValues => {
  const { phonesSourceType, phonesSourceTextarea } = getPhonesSource()

  const routeGroupId = props.isSmartDelivery ? '0' : 'sms'
  const getRoutedId = saveRoutedId.value ? saveRoutedId.value : routeGroupId
  const rus = !props.rusByDefaultDisabled
  const defaultBlackListId = props?.defaultBlackListId
    ? [props.defaultBlackListId]
    : []

  return {
    isSameMessages: booleanToCheckbox(false),
    phonesSourceType,
    phonesSourceTextarea,
    blackListId: defaultBlackListId,
    // ignorePhoneFormat: booleanToCheckbox(true),
    routeGroupId: getRoutedId,
    originator: props.defaultOriginator,
    channels: {},
    rus: booleanToCheckbox(rus),
    sendImmediately: booleanToCheckbox(true),
    startDate: globalTime.getLocalPaternDate(),
    stopTime: booleanToCheckbox(false),
    timeout: 0,
    endTime: '',
    startTimeNextDay: '',
    localtime: booleanToCheckbox(false),
    sendEvenly: booleanToCheckbox(false),
    gap: 0.05,
  }
}

const {
  handleSubmit,
  meta,
  setFieldValue,
  values: valuesForm,
  resetForm,
} = useForm<FormValues>({
  initialValues: computed(() => getInitialValues()),
  // @ts-expect-error Описана минимально-достаточная схема для валидации
  validationSchema: computed(() => getValidationSchema(props.routes)),
})
const formValues = useFormValues<FormValues>()

const routeGroupIdValue = useFieldValue<string>('routeGroupId')

displayData.value = computed(() => {
  return {
    time: valuesForm.startDateTimePicker,
    date: valuesForm.startDateDatePicker,
  }
})

const isSubmitDisabled = computed(() => !meta.value.dirty)
const isSendEvenly = computed(() =>
  checkboxToBoolean(formValues.value.sendEvenly ?? [])
)
const isStopTime = computed(() =>
  checkboxToBoolean(formValues.value.stopTime ?? [])
)
const endTime = computed(() => formValues.value.endTime ?? '')
const startTimeNextDayField = computed(
  () => formValues?.value?.startTimeNextDay ?? ''
)

const isFilledStopFields = computed(
  () =>
    Boolean(formValues.value.startTimeNextDay) &&
    Boolean(formValues.value.endTime)
)

const isPhoneSourceWithMessage = computed(() => {
  if (formValues.value.phonesSourceType) {
    return [
      PhonesType.LineSeparatedWithMessages,
      PhonesTypeFile.LineSeparatedWithMessagesFile,
    ].includes(formValues.value.phonesSourceType)
  }

  return false
})

const emit = defineEmits(['submit', 'test'])

/*
  SimpleSendSmsForm => BaseSmsForm & DefaultSendInput
  SimpleSendSmartForm => BaseSmartForm & DefaultSendInput

  BaseSmsForm => BaseSendForm & { message?: string }
  BaseSmartForm => BaseSendForm & {
    routeGroupId: string
    channels: Record<
      string,
      {
        message: string
        buttonName?: string
        buttonUrl?: string
        fileName?: string
      }
    >
  }

  GroupsSendSmsForm => { selectedElements: SelectedElements } & BaseSmsForm
  GroupsSendSmartForm => { selectedElements: SelectedElements } & BaseSmartForm

*/

const getPhonesSourceDataToRequest = (
  phonesSourceType: PhonesType | ExtraPhonesType,
  phonesSourceTextarea: string | string[] | undefined
) => {
  if (phonesSourceType === PhonesSourceTypeEnums.SelectedGroups) {
    return {
      selectedElements: props.selectedGroups,
    }
  }

  if (phonesSourceType === PhonesSourceTypeEnums.SelectedPhones) {
    return {
      selectedElements: props.selectedPhonesData?.selectedPhones,
    }
  }

  const phonesInput = Array.isArray(phonesSourceTextarea)
    ? phonesSourceTextarea.join(', ')
    : phonesSourceTextarea

  return {
    phonesType: phonesSourceType,
    phonesInput,
  }
}

const prepareDataToRequest = (values: FormValues) => {
  const blackListId =
    values?.blackListId?.length === 0 ? undefined : values.blackListId
  const timeOutSms = values?.timeOutsms

  // console.log('timeOutSms', timeOutSms)
  /*
    Из-за особенности работы vee-validate с частями формы которые
    появляются и скрываются тут значение может быть undefined
  */
  const transliterate = !checkboxToBoolean(values.rus)
  const dateStart = values.startDate

  const gapTime = values.gapTime
    ? formatStringTimeToServerTime(values.gapTime, values.startDateDatePicker)
    : values.gapTime

  const startDate = dateStart

  const endTimeField = values.endTime
    ? formatStringTimeToServerTime(values.endTime, values.startDateDatePicker)
    : undefined

  const startTimeNextDay = values.startTimeNextDay
    ? formatStringTimeToServerTime(
        values.startTimeNextDay,
        values.startDateDatePicker
      )
    : undefined

  const commonData = {
    batchName: values.batchName,
    blackListId,
    // ignorePhoneFormat: checkboxToBoolean(values.ignorePhoneFormat),
    originator: values.originator,
    transliterate,
    startDate,
    startTimeNextDay,
    endTime: endTimeField,
    gapTime,
    // timeout: values.timeout,
    localtime: checkboxToBoolean(values.localtime),
    gap: values.gap,
    fileName: values.phonesFile?.name,
    phonesCount: values.phonesFile?.phonesCount,
  }
  // console.log('values.channels', values.channels)
  const messageCommonData = props.isSmartDelivery
    ? {
        routeGroupId: values.routeGroupId,
        channels: values.channels,
      }
    : {
        message: values.channels.sms?.message,
        // timeout: Number(timeOutSms),
      }

  const messageData = computed(() => {
    if (isPhoneSourceWithMessage.value && props.isSmartDelivery) {
      if (timeOutSms) {
        return {
          ...messageCommonData,
          channels: {},
          // timeout: Number(timeOutSms),
        }
      } else {
        return {
          ...messageCommonData,
          channels: {},
        }
      }
    } else {
      return messageCommonData
    }
  })

  if (!props.isSmartDelivery && timeOutSms) {
    messageCommonData.timeout = Number(timeOutSms)
  }
  if (isPhoneSourceWithMessage.value && props.isSmartDelivery && timeOutSms) {
    messageCommonData.timeout = Number(timeOutSms)
  }

  const phonesSourceData = getPhonesSourceDataToRequest(
    values.phonesSourceType,
    values.phonesSourceTextarea
  )

  return {
    ...commonData,
    ...messageData.value,
    ...phonesSourceData,
  }
}

const onSubmit = handleSubmit((values) => {
  emit('submit', prepareDataToRequest(values))
})

watchEffect(() => {
  if (isPhoneSourceWithMessage.value) {
    setFieldValue('channels', {})
  }
})
const getSelectedGroup = (id) => {
  const gg = toRaw(props.routes)?.filter((item) => item.gid === id)
  return gg?.at(0).channels
}

/* Функция "Одинаковые сообщения по всем каналам" */
watchEffect(() => {
  const { routeGroupId, channels, isSameMessages } = formValues.value

  if (!routeGroupId) {
    return
  }

  const selectedRoutes = getSelectedGroup(routeGroupId)

  const targetRoute = selectedRoutes.at(0)
  const dependentRoutes = [...selectedRoutes].splice(1)

  const targetRouteMessage = channels?.[targetRoute.name]?.message

  if (
    checkboxToBoolean(isSameMessages ?? [false]) &&
    targetRouteMessage !== undefined
  ) {
    dependentRoutes.forEach((route) => {
      if (route) {
        // @ts-expect-error Проблемы в типизации библиотеки
        // https://github.com/logaretm/vee-validate/discussions/3681#discussioncomment-2129278
        setFieldValue(`channels.${route.name}.message`, targetRouteMessage)
      }
    })
  }
})

const setNewTimeValue = (nameField: string, fieldValue: string) => {
  if (fieldValue) {
    if (fieldValue.split(':').length <= 2) {
      setFieldValue(nameField, fieldValue + ':00')
    }
    if (fieldValue.split(':')[2]?.length === 1) {
      setFieldValue(nameField, fieldValue + '00')
    }
  }
}

watchEffect(() => {
  if (isStopTime.value) {
    setFieldValue('gapTime', '')
  } else {
    setFieldValue('endTime', '')
    setFieldValue('startTimeNextDay', '')
  }
})
watch(
  () => endTime.value,
  () => {
    setNewTimeValue('endTime', endTime.value)
  }
)
watch(
  () => startTimeNextDayField?.value,
  () => {
    setNewTimeValue('startTimeNextDay', startTimeNextDayField?.value)
  }
)
watchEffect(() => {
  if (isSendEvenly.value) {
    setFieldValue('gapTime', '00:00:00')
  } else {
    setFieldValue('gapTime', '')
  }
})

watchEffect(() => {
  if (formValues.value.endTime && isSendEvenly.value) {
    setFieldValue('gapTime', formValues.value.endTime)
  } else if (
    formValues.value.endTime === '' &&
    isSendEvenly.value &&
    isStopTime.value
  ) {
    setFieldValue('gapTime', '')
  }
})

watchEffect(() => {
  if (isFilledStopFields.value) {
    setFieldValue('sendEvenly', booleanToCheckbox(false))
  }
})

watch(
  () => props.submitted,
  (submitted) => {
    if (submitted) {
      saveRoutedId.value = routeGroupIdValue.value
      void resetForm()
    }
  }
)

const onTest = handleSubmit((values) => {
  emit('test', prepareDataToRequest(values))
})

const isSmsRoute = computed(() => {
  return Object.keys(valuesForm.channels).every(
    (channel: string) => channel === 'sms'
  )
})

const showTestButton = computed(
  () => !profile.value?.roles?.includes(Role.CreateBatchesOnly)
)
</script>

<style module>
.errorContainer {
  margin-top: var(--gap-32);
}

.testButton {
  padding-right: var(--gap-12);
  padding-left: var(--gap-12);
}

.buttons {
  display: flex;
  margin-top: var(--gap-32);

  column-gap: var(--gap-16);
}
</style>
