import './style.css'
import './vars.css'
import '@smst/ui/lib/style.css'

import { createHead } from '@vueuse/head'
import { createApp } from 'vue'
import type { VueQueryPluginOptions } from 'vue-query'
import { VueQueryPlugin } from 'vue-query'
import VueAnalytics from 'vue3-analytics'
import VueYandexMetrika from 'vue3-yandex-metrika'
import vSelect from 'vue-select'

// import * as Sentry from '@sentry/vue'
// const dsn = import.meta.env.VITE_SENTRY_DNS_PUBLIC_KEY as string
import { checkFrontByDomain } from '@/utils/getDomain'

// const mainHost = location.hostname

import App from './App.vue'
import { i18n } from './i18n'
import { router } from './router'
import { SentryInit } from './utils/sentry/sentry.config'

const app = createApp(App)
const head = createHead()
SentryInit(app, router)
app.use(i18n)
app.use(router)
app.use(head)

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  },
}

if (!checkFrontByDomain()) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  app.use(VueAnalytics, {
    id: 'UA-81508828-2',
    router,
    checkDuplicatedScript: true,
  })
}
if (!import.meta.env.DEV && !checkFrontByDomain()) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  app.use(VueYandexMetrika, {
    id: 38746440,
    router,
    env: 'production',
    // other options
  })
}
app.component('v-select', vSelect)
app.use(VueQueryPlugin, vueQueryPluginOptions)

app.mount('#app')
