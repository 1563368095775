<template>
    <SelectAsync
      name="originator"
      :label="t('filters.originator')"
      :searchable="true"
      :options="optionsOriginator"
      :class="$style.select"
      @handlePrevPage="handlePrevPage"
      @handleNextPage="handleNextPage"
      :isLoading="isLoading"
      :hasNextPage="hasNextPage"
      :hasPrevPage="hasPrevPage"
      :placeholder="t('filters.originator')"
      :placeholderBottom="t('filters.placeholderBottom')"
      @search="handleSearch"
    />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import { apiClient } from '@/api'
import SelectAsync from '@/components/SelectAsync/SelectAsync.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'

// const { handleChange: handleOriginator, value: date } = useField<string>(
//   'originator',
//   {},
//   {
//     initialValue: '',
//   }
// )

const t = useComponentI18n('originators')

const isLoading = ref(false)
const optionsOriginator = ref([])
const optionsPagination = ref()
const searchText = ref()
// const originatorModel = ref()

const getPage = (page: 'next' | 'prev' | 'start') => {
  if(page === 'next') {
    return {
      page: optionsPagination?.value?.currentPage + 1,
      size: 10
    }
  }
  if(page === 'start') {
    return {
      page: 1,
      size: 10
    }
  }
  if(page === 'prev') {
    return {
      page: optionsPagination?.value?.currentPage - 1,
      size: 10
    }
  }
}
// const handleChange = (val) => {
//   //console.log('handleChange', val);
//   handleOriginator(val)
// }

const hasNextPage = computed(() => {
  if(!searchText?.value?.length) {
  const currentPage = optionsPagination?.value?.currentPage
  const totalPages = optionsPagination?.value?.totalPages
  const page = optionsPagination?.value ? currentPage < totalPages : false
  if(page) return true
  return false
  } else return false
})
const hasPrevPage = computed(() => {
  // //console.log('optionsPagination', optionsPagination?.value, Number(optionsPagination?.value?.currentPage) === 1);
   if(!optionsPagination?.value || optionsPagination?.value?.currentPage === 1) return false
  //  if(optionsPagination?.value.currentPage ) return false
   return true
})

const setPagination = (data, search?: boolean = false) => {
    if(search && data) {
        optionsPagination.value = null
        optionsOriginator.value = data?.map((item) => {
        return {
          value: item,
          label: item,
        }
      })
    }
    if (data?.data?.list) {
    optionsPagination.value = data?.data?.pageInfo
    optionsOriginator.value = data?.data?.list.map((item) => {
      return {
        value: item.originator,
        label: item.originator,
      }
    })
  }
}

const handleNextPage = async () => {
//  //console.log('handleNextPage');
  if(hasNextPage.value && !searchText?.value?.length) {
    isLoading.value = true
      const hh = await apiClient.originators.originators_GET(getPage('next'))
      // //console.log('handleNextPage', hh);
      setPagination(hh)
    isLoading.value = false
  }
}

const handlePrevPage = async () => {

  // //console.log('handlePrevPage');

  if(hasPrevPage.value && !searchText?.value?.length) {
     try {
    isLoading.value = true
    const hh = await apiClient.originators.originators_GET(getPage('prev'))
    // //console.log('handlePrevPage', hh);
    setPagination(hh)
     } catch(err) {
      // //console.log('err', err);
      }finally {
        isLoading.value = false
      }


  }
}

const handleSearch = async (isSearchText: string) => {
  // //console.log('searchText', isSearchText);
  searchText.value = isSearchText
  if(isSearchText.length > 1) await getSearchOptions()
  else if(isSearchText.length === 0) {
    try {
    const hh = await apiClient.originators.originators_GET(getPage('start'))
    // //console.log('hh', hh);
    setPagination(hh)
      }catch(err) {

      }finally {

      }
  }
}
// const getSearchQuery = () => {

// }

const getSearchOptions = async () => {
  try {
    isLoading.value = true
    const isOriginators = await apiClient.originators.findOriginators_GET({originator: searchText?.value, useLike: true})
    setPagination(isOriginators?.data?.list, true)
    // //console.log('isOriginators', isOriginators);
    return isOriginators
  } catch(err) {
    // //console.log('err', err);
  }finally {
    isLoading.value = false
  }

}

onMounted(async () => {
  const hh = await apiClient.originators.originators_GET(getPage('start'))
  //console.log('hh', hh);
  setPagination(hh)
  // optionsOriginator.value = hh?.value?.data?.list ?? []
})

</script>

<style module>
.selectn {
  margin-bottom: 16px;
}
</style>
